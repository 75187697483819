import * as Dapp from '@elrondnetwork/dapp';

export const walletConnectBridge ='https://bridge.walletconnect.org';
export const walletConnectDeepLink = 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/';
export const NFTCollection = 'PBC-832a7b';
export const NFTContract = 'erd1qqqqqqqqqqqqqpgqd4h68uygwyzjhqv54yjy77anj26x0w2gxu3sqpj4n4';

export const network: Dapp.NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};