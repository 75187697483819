/* eslint-disable prettier/prettier */
import MainLayout from './MainLayout';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Axios from "axios";
import { useEffect, useRef, useState } from 'react';
import { getRemainingNfts } from '../apiEndpoints';
import * as Dapp from '@elrondnetwork/dapp';

import Countdown from 'react-countdown';
import MintTab from './Dashboard/MintTab';
import AccountTab from './Dashboard/AccountTab';

const Home: React.FC = () => {
  const history = useHistory();
  const { account, address, explorerAddress } = Dapp.useContext();
  const [remainingNFT, setRemainingNfts] = useState([]);
  const [pending, setPending] = useState(false);
  const mounted = useRef(true);
  const SERVER_API ="https://app.piggyband.io/api";
  const search = window.location.search; 
  const param= new URLSearchParams(search);
  const [msg,setMsg] = useState();

  useEffect(() => {
    const refferal=param.get('refferal');
    if(refferal){
      
        Axios.post(`${SERVER_API+"/checkUser"}`, {
          ref_id: refferal

        })
        .then(function (response) {
          const data=response.data;
          if(data.success==false){
            setMsg(data.msg);

          }else{
            localStorage.setItem('user_refferal',refferal);
          }
        })
       
  

    }
   
  })
  
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    const fetchRemainingNfts = async () => {
      const response = await fetch(getRemainingNfts(), { signal });
      const data = await response.json();
      if (mounted.current) {
        setRemainingNfts(data);
        setPending(false);
      }
    };
    fetchRemainingNfts();
    return () => {
      mounted.current = false;
    };
  }, [address]);

  const unlock = () => {
    history.push('/unlock');
  };

  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const date = new Date(2022, 1, 13, 14, 0, 0);

  return (
    <MainLayout>
      {msg ? <div className='alert alert-danger text-center' dangerouslySetInnerHTML={{__html: msg}}></div> :""}
      <MintTab/>
      {(account && address) ?
     <AccountTab/>
     : ""}
    </MainLayout>
  );
};

export default Home;
function refferal(arg0: string, refferal: string) {
  throw new Error('Function not implemented.');
}

