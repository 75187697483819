import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Pane, Tablist, Tab, Alert } from 'evergreen-ui';
import MainLayout from '../MainLayout';
import AccountTab from './AccountTab';
import * as Dapp from '@elrondnetwork/dapp';
import MintTab from './MintTab';
import Axios from "axios";
import { off } from 'process';
import { useLocation } from 'react-router-dom';
const tabs = ['Mint', 'My NFTs'];
const search = window.location.search; 
const param= new URLSearchParams(search);
const Dashboard: React.FC = () => {
  const location= useLocation();
  const [selectedIndex, setTabSelectedIndex] = useState(0);
  const [NftStatus, setNftStatus] = useState(Boolean);
  const { account, address, explorerAddress } = Dapp.useContext();

  const SERVER_API ="https://app.piggyband.io/api";
  const NFT_PRICE=0.40;
  useEffect(() => {
   
   const status=param.get('status');
   const reqaddress=param.get('address');
   const txHash=param.get('txHash');

   console.log('');
   setInterval(()=> {
    console.log(location.search);
   },5000);
   if(status=="fail" && txHash){
    setTimeout(() => {
      window.location.href="/";
  }
  , 
  2000
  );
   }
   if(status=="success" && txHash){

    Axios.get('https://testnet-api.elrond.com/transactions/'+txHash).then(function (res) {
      const transaction=res.data;
      const useramount= (transaction.value/1e18).toFixed(2);
      Axios.post(`${SERVER_API+"/nftregsiter"}`, {
        "wallet_address": transaction.sender,
        "contract_address": transaction.receiver,
        "transaction_hash": transaction.txHash,
        "status": transaction.status,
        "total_amt": useramount,
        "total_nft":  (1/NFT_PRICE) * Number(useramount),
        'currency_price': transaction.price,
        "refferal_id": localStorage.getItem('user_refferal') ? localStorage.getItem('user_refferal') : "" ,
        'currency' : "XeGLD"
    })
      .then(function (response) {
        if(response.data.status===true){
             setNftStatus(true);
             setTimeout(() => {
              localStorage.removeItem('user_refferal');
            
              window.location.href="/";
          }
          ,
          2000
          );
          
            
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    })
    .catch(function (error) {
      console.log(error);
    });
   
}

  },[address]);


  

  return (
    <MainLayout>
       {(NftStatus==true) ? <div className="alert alert-success text-center bg-sucess p-4">Succesfully NFT Minted</div> : ""}
      <MintTab/>
      {(account && address) ?
     <AccountTab/>
     : ""}
    </MainLayout>
  );
};

export default Dashboard;
