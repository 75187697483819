/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
import * as Dapp from '@elrondnetwork/dapp';
import { useHistory } from 'react-router-dom';
import {
  Pane,
  Heading,
  majorScale,
  StatusIndicator,
  Popover,
  Menu,
  Position,
  Strong
} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { shortenWalletAddress } from '../utils';
import { routes } from '../routes';
import { ChainID } from '../types';
import Unlock from './Unlock';

const MainLayout: React.FC = ({ children }) => {
  const { address, chainId } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const smallRes = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const getChainName = (chainID: ChainID) => {
    if (chainID === ChainID.TESTNET) return 'Elrond Testnet';
    if (chainID === ChainID.DEVNET) return 'Elrond Devnet';
    if (chainID === ChainID.MAINNET) return 'Elrond Mainnet';
    return 'Elrond Mainnet';
  };

  const logOut = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push('/');
  };

  return (
    <Pane>
      <Dapp.Authenticate routes={routes} unlockRoute="/unlock">
        <Pane background="transparent" marginBottom={30} border="none">
          <Pane
            maxWidth={1200}
            paddingX={30}
            marginX="auto"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height={majorScale(8)}
            className="space-between"
          >
            <Pane display="flex">
              <img
                src="https://piggyband.io/assets/images/logo/logo-dark.png"
                onClick={() => history.push('/')} width="200"
               
              />
               
              
             
            
            </Pane>
            
            {address ? (
              <Pane display="flex" alignItems="center" marginTop={10}>
                 {!smallRes && (
                <StatusIndicator color="success" marginRight={20} marginTop={5} className="text">
                  {getChainName(chainId.valueOf() as ChainID)}
                </StatusIndicator>
              )}
                <Popover
                  position={Position.BOTTOM_RIGHT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item onClick={logOut} >Logout</Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <StatusIndicator
                    title={address}
                    cursor="pointer"
                    className="sc-button"
                  >
                    {shortenWalletAddress(address)}
                  </StatusIndicator>
                </Popover>
              </Pane>
            ) : (
              <Pane>
                <Strong
                 onClick={() => history.push('/unlock')}
                  cursor="pointer"
                  className="sc-button "
                >
                  Connect to Elrond Wallet
                </Strong>
              </Pane>
            )}
          </Pane>
        </Pane>
        <Pane
          maxWidth={1200}
          paddingX={30}
          marginX="auto"
          width="100%"
          paddingBottom={40}
        >
          {children}
        </Pane>
      </Dapp.Authenticate>
    </Pane >
  );
};

export default MainLayout;
